class Editor {
  constructor({el, toHtml}) {
    this.el = el
    this.toHtml = toHtml
    this.textarea = el.querySelector('textarea')
    this.preview = el.querySelector('.me-preview')

    this._handleClick('.me-button-bold', () => {
      this._emButton('bold', '**')
    })

    this._handleClick('.me-button-italic', () => {
      this._emButton('italic', '*')
    })

    this._handleClick('.me-button-header', () => {
      this._changeCurrentLine(line => {
        let cursorShift

        if (line.match(/^#+ /)) {
          line = '#' + line
          cursorShift = 1
        } else {
          line = '# ' + line
          cursorShift = 2
        }

        return {line, cursorShift}
      })
    })

    this._handleClick('.me-button-quote', () => {
      this._toggleBlockMark('> ')
    })

    this._handleClick('.me-button-ul', () => {
      this._toggleBlockMark('* ')
    })

    this._handleClick('.me-button-ol', () => {
      this._toggleBlockMark('1. ')
    })

    this._handleClick('.me-button-preview', () => {
      this.preview.innerHTML = this.toHtml(this.textarea.value)
    })

    this._handleClick('.me-button-link', () => {
      this._emButton('link', '[', '](http://)')
    })
  }

  uploadStarted(file) {
    const cursor = this.textarea.selectionStart
    const currentText = this.textarea.value
    this.textarea.value = currentText.slice(0, cursor) + `![${file}](uploading...)` + currentText.slice(cursor)
  }

  uploadComplete(file, url) {
    this.textarea.value = this.textarea.value.replace(`![${file}](uploading...)`, `![image](${url})`)
  }

  _currentSelection() {
    // Works in Firefox too (where window.getSelection().toString() returns "")
    return this.textarea.value.substring(this.textarea.selectionStart, this.textarea.selectionEnd)
  }

  _handleClick(sel, cb) {
    const button = this.el.querySelector(sel)
    if (button) {
      button.addEventListener('click', cb)
    }
  }

  _toggleBlockMark(mark) {
    this._changeCurrentLine(line => {
      let cursorShift

      if (line.slice(0, mark.length) == mark) {
        line = line.slice(mark.length)
        cursorShift = -mark.length
      } else {
        line = mark + line
        cursorShift = mark.length
      }

      return {line, cursorShift}
    })
  }

  _changeCurrentLine(modifierFn) {
    this.textarea.focus()

    const cursor = this.textarea.selectionStart
    let cursorShift

    let newText = this.textarea.value.split('\n').reduce((result, line) => {
      if (result.length <= cursor && cursor <= (result + line).length) {
        const lineChange = modifierFn(line)
        line = lineChange.line
        cursorShift = lineChange.cursorShift
      }
      result = result + line + '\n'
      return result
    }, '')

    // rm trailing new line
    this.textarea.value = newText.slice(0, newText.length-1)
    this.textarea.setSelectionRange(cursor+cursorShift, cursor+cursorShift)
  }

  _emButton(name, mark, endMark) {
    this.textarea.focus()
    endMark = endMark || mark
    const cursor = this.textarea.selectionStart
    const selectionEnd = this.textarea.selectionEnd
    const text = this.textarea.value
    const textAroundCursor = text.slice(cursor-mark.length, cursor) + text.slice(selectionEnd, selectionEnd+endMark.length)

    if (cursor != selectionEnd) {
      if (textAroundCursor == mark+endMark) {
        this.textarea.value = text.slice(0, cursor-mark.length) + this._currentSelection() + text.slice(selectionEnd+endMark.length)
        this.textarea.setSelectionRange(cursor - mark.length, selectionEnd-mark.length)
      } else {
        this.textarea.value = text.slice(0, cursor) + mark + this._currentSelection() + endMark + text.slice(selectionEnd)
        this.textarea.setSelectionRange(cursor+mark.length, selectionEnd+mark.length)
      }
    } else {
      if (cursor >= mark.length && cursor + endMark.length <= text.length) {
        if (textAroundCursor == mark+endMark) {
          this.textarea.value = text.slice(0, cursor-mark.length) + text.slice(cursor+endMark.length)
          this.textarea.setSelectionRange(cursor-mark.length, cursor-mark.length)
        } else {
          this.textarea.value = text.slice(0, cursor) + mark + endMark + text.slice(cursor)
          this.textarea.setSelectionRange(cursor+mark.length, cursor+mark.length)
        }
      } else {
        this.textarea.value = text.slice(0, cursor) + mark + endMark + text.slice(cursor)
        this.textarea.setSelectionRange(cursor+mark.length, cursor+mark.length)
      }
    }
  }
}

module.exports = Editor
